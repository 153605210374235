import React from "react";
import { ColDef, GridOptions } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";

export const RowNumberColumn = (selectable = false, headerName = "№"): ColDef => ({
  headerName,
  valueGetter: "node.rowIndex + 1",
  width: selectable ? 50 : 50,
  sortable: false,
  resizable: false,
  cellClass: "righted row-number",
  checkboxSelection: selectable,
});

export const FIGMA_TABLE_ICON_SIZE = 10;

export const TableIcon = (name: string, size = 14, asString = true) =>
  asString ? (
    `<i class="fa ${name}" aria-hidden="true" style="font-size:${size}px; width:${size}px; height:${size}px; color: #182026"></i>`
  ) : (
    <i
      className={`fa ${name}`}
      aria-hidden="true"
      style={{
        fontSize: `${size}px`,
        width: `${size}px`,
        height: `${size}px`,
        color: "#182026",
      }}
    />
  );

export const DefaultAgGridOptions = (figma = false): GridOptions => ({
  icons: {
    sortAscending: TableIcon("fa-caret-up", figma ? FIGMA_TABLE_ICON_SIZE : 14),
    sortDescending: TableIcon("fa-caret-down", figma ? FIGMA_TABLE_ICON_SIZE : 14),
    checkboxChecked: '<GeneralIcon type="general-checkbox" />',
    checkboxUnchecked: '<GeneralIcon type="general-zero-checkbox" />',
  },
  sortingOrder: ["desc", "asc"],
  treeData: true,
  groupDefaultExpanded: 1,
  getDataPath: (data) => data.path,
  animateRows: true,
  // autoGroupColumnDef: {
  //   headerName: "Наименование",
  //   field: "name",
  //   cellRendererParams: {
  //     suppressCount: true,
  //   },
  // },
  // rowDragManaged: true,
  // onRowDragEnter: onRowDragEnter,
  // onRowDragEnd: onRowDragEnd,
  // onRowDragMove: onRowDragMove,
  // onRowDragLeave: onRowDragLeave,
});

// function onRowDragEnter(e: RowDragEnterEvent) {
//   console.info("onRowDragEnter", e);
// }

// function onRowDragEnd(e: RowDragEndEvent) {
//   console.info("onRowDragEnd", e);
// }

// function onRowDragMove(e: any) {
//   console.info("onRowDragMove", e);
// }

// function onRowDragLeave(e: any) {
//   console.info("onRowDragLeave", e);
// }

export const defaultModules = [ClientSideRowModelModule];

export const ROW_HEIGHT = 20;
export const HEADER_HEIGHT = 29;

export const FIGMA_ROW_HEIGHT = 22;
export const FIGMA_HEADER_HEIGHT = 26;
