import React from "react";
import { observer } from "mobx-react";
import styles from "./CategoriesList.module.scss";
import { columns } from "./columns";
import { saveAgGridColumnSize } from "modules/common/services/table/size-storage";
import { defaultModules, DefaultAgGridOptions } from "modules/common/services/table/helpers";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { agGridEntityContextMenu } from "modules/common/components/table/EntityContextMenu";
import { CategoryList, CategorysListType } from "../../models/category-list";
import { CategorySnapshotType, formatCategory } from "../../models/category";
import { MenuModule } from "@ag-grid-enterprise/menu";
import {
  GetContextMenuItemsParams,
  RowClickedEvent,
  RowSelectedEvent,
  CellContextMenuEvent,
  GridReadyEvent,
} from "@ag-grid-community/core";
import copyToClipboard from "copy-to-clipboard";
import { GridTheme } from "modules/common/components/table/GridTheme";
import { AgGridReact } from "@ag-grid-community/react/lib/agGridReact";
import { agGridLocale } from "modules/root/services/locale";
import { MAX_INT } from "modules/common/constants";

interface CategorysListProps {
  list: CategorysListType;
  onRemoved: () => void;
  onSelected: (id: string) => void;
  onClicked: (id: string) => void;
}

interface CategorysListState {
  focused: boolean;
}
export const CategorysList = observer(
  class extends React.Component<CategorysListProps, CategorysListState> {
    private gridOptions = DefaultAgGridOptions(true);

    private modules = [...defaultModules, MenuModule];
    private TABLE_NAME = CategoryList.name;
    private config = columns(this.TABLE_NAME);
    private sizeSaver = saveAgGridColumnSize(this.TABLE_NAME);

    constructor(props: CategorysListProps) {
      super(props);
      this.state = { focused: false };
    }

    render() {
      const { list, onSelected } = this.props;

      return (
        <RemoveConfirmation<CategorySnapshotType>
          what={removeLabel}
          onConfirmed={this.removeCategory}
          observation={list.data}
        >
          {({ confirmRemoving }) => {
            const getContextMenuItems = ({ node }: GetContextMenuItemsParams) =>
              agGridEntityContextMenu({
                onCopy: () => copyToClipboard(formatCategory(node.data), { format: "text/plain" }),
                onChange: () => onSelected(getRowNodeId(node.data)),
                onRemove: () => confirmRemoving(node.data),
              });

            return (
              <GridTheme bordered={true} className={`designed ${styles.grid}`}>
                <AgGridReact
                  rowStyle={{
                    borderBottom: "1 px solid #EDF0F2",
                    fontFamily: "GothamPro, Arial, sans-serif",
                    color: "#00273D",

                    fontSize: "12px",
                  }}
                  rowSelection="single"
                  suppressCellSelection={true}
                  enableCellTextSelection={true}
                  columnDefs={this.config}
                  rowData={list.data}
                  localeText={agGridLocale}
                  onRowDoubleClicked={this.onRowSelected}
                  onRowSelected={this.onRowClicked}
                  getRowNodeId={getRowNodeId}
                  rowHeight={32}
                  getContextMenuItems={getContextMenuItems}
                  onGridReady={this.onGridReady}
                  onCellContextMenu={onCellContextMenu}
                  onSortChanged={list.sorter.resorted}
                  onColumnResized={this.sizeSaver}
                  gridOptions={this.gridOptions}
                  modules={this.modules}
                  immutableData={true}
                  rowBuffer={MAX_INT}
                  onFirstDataRendered={this.onRowChanged}
                />
              </GridTheme>
            );
          }}
        </RemoveConfirmation>
      );
    }

    toggle = () => this.setState({ focused: true });

    onRowChanged = () => {
      this.gridOptions.api?.forEachNode((node) => {
        if (node.rowIndex === 0) {
          node.setSelected(true);
        }
      });
    };

    removeCategory = async (dep: CategorySnapshotType) => {
      const { list, onRemoved } = this.props;

      const success = await list.delete(dep);
      success && onRemoved();
    };

    onGridReady = async (event: GridReadyEvent) => {
      const { list } = this.props;

      setTimeout(() => event.api.setSortModel(list.sorter.agGridSortModel));
    };

    onRowSelected = ({ data }: RowSelectedEvent) => {
      const { onSelected } = this.props;

      onSelected(getRowNodeId(data));
    };

    onRowClicked = ({ data, node }: RowClickedEvent) => {
      const { onClicked } = this.props;

      if (!node.isSelected()) {
        return;
      }

      onClicked(getRowNodeId(data));
      this.toggle();
    };
  }
);

const getRowNodeId = (e: CategorySnapshotType) => {
  return e.id;
};

const onCellContextMenu = ({ node }: CellContextMenuEvent) => {
  node && node.setSelected(true, true);
};

const removeLabel = (dep: CategorySnapshotType) => `категорию ${dep.name}`;
